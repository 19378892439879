* {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 22px;
  margin: 0px;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container p {
  margin-bottom: 31px;
}

.container p:nth-child(10) {
  margin-top: 43px;
  margin-bottom: 40px;
}

.sign {
  font-weight: bold;
}

.bg {
  background: #CFE8FF;
}

.main-pic {
  margin: 53px auto;
  width: 200px;
  height: 200px;
  border-radius: 999px;
}

.container h2 {
  display: inline-block;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 39px;
  text-align: center;
}

.container-projects {
  display: flex;
  justify-content: space-evenly;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1350px) {
  .container-projects{
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .container-projects{
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  body {
    padding-left: 10px;
    padding-right: 10px;
  }
}


.container-projects a {
  display: inline-block;
  text-decoration-line: underline;
  color: #000000;
  line-height: 23px;
  font-weight: 700;
  margin-bottom: 12px;
}

.project {
  border: 3px solid #000000;
  width: 90%;
  background-color: white;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 450px;
}

.project img {
  height: 215px;
  object-fit: cover;
}

.project:hover {
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
}

.project-img {
  width: 100%;
}

.project p {
  margin-top: 20px;
  margin-left: 36px;
  margin-bottom: 0px;
}

@media screen and (max-width: 1350px) { 
  .project {
    width: 100%;
  } 
}


.project-decr { 
  margin-top: 13px;
  margin-left: 36px;
  color: #7C7C7C;
  font-size: 18px;
}

.git-link {
  display: flex;
  flex-direction: row;
  font-size: 15px;
}

.git-link a {
  margin-top: 23px;
  margin-left: 0px;
  font-size: 20px;
}

.git-link img {
  margin: 21px 12px 30px 36px;
  width: 24px;
  height: 24px;
}

.recommendation {
  margin-top: 90px;
}

.recommendation p:first-child {
  margin-bottom: 26px;
}

.line { 
  border-left: 10px solid black;
  margin-left: 0px; 
  padding-left: 17px; 
  font-weight: bold;
}

.recommendation p:nth-child(3) {
  display: inline-block;
  color: #7D7D7D;
  margin: -10px 24px 0px 24px;
}

.end {
  margin-top: 107px;
  margin-bottom: 106px;
}